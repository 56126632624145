
.main-col-custom {
    background-color: #A6E3E9;
    border-radius: 15px;
    padding: 30px;
    padding-right: 2% !important;
    padding-left: 2% !important;
}

.navbar {
    background-color: #05445E !important;
}

.navbar-brand {
    color: #D4F1F4 !important;
}

.Bold {
    font-weight: bold;
  }

.Button_Main {
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
    width: 200px;
    height: 100px;
    font-size: 20px !important;
    margin-bottom: 10px;
    font-size: 40px;
    padding: 15px !important;
}

.Button_Main:hover {
    background-color: #05445E;
    color: white !important;
}

.dropdown-item:hover {
    background-color: #d4f1f44f !important;
    color: rgb(255, 255, 255) !important;}

.dropdown-item {
    color: rgba(255, 255, 255, 0.55) !important;}

.dropdown-menu {
    background-color: #05445E !important;
}

.dropdown-toggle {
    border-radius: 10px;
    color: #D4F1F4 !important;
}

.dropdown-toggle:hover {
    border-radius: 10px;
    background-color: #d4f1f44f !important;
}

.Button {
    background-color: rgb(255, 255, 255);
    color: #007bff;
    border-radius: 5px;
    height: 90px;
    width: 200px;
    font-size: 30px;
    margin-bottom: 10px;
    align-self: center;
    font-size: 20px;
}

.navlinks {
    border-radius: 10px;
    color: #D4F1F4 !important;
}

.navlinks-disabled {
    border-radius: 10px;
    color: rgb(255, 255, 255, 0.55) !important;
}

.navlinks:hover {
    border-radius: 10px;
    background-color: #d4f1f44f !important;
}

.Cards {
    margin: 5px;
    width: calc(100vw/5);
}

.container-custom {
    background-color: #E3FDFD;
    min-height: 100vh;
}

.heighting {
    padding-bottom: 30px;
}

.homepage-row {
    background-color: rgb(190, 190, 201);
    height: 70px;
    width: 150px;
    padding: 15px;
    border-radius: 15px;
    margin-bottom: 15px;
    margin-inline: 10px;
}

.amplify-tabs {
    display: none;
  }

.firm-header {
    font-size: 40px;
    color: #05445E !important
}

.firm-header2 {
    font-size: 30px;
    color: #05445E !important
}

.firm-header3 {
    font-size: 20px;
    color: #05445E !important
}

.table-bordered {
    border: #05445E solid 1px !important;
  }

.table-striped {
    border: #05445E solid 1px !important;
}

.table-hover tbody tr:hover {
    background-color: #E3FDFD;
}

.tablesearch {
    background-color: #05445E;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.expanding-foo {
    background-color: #007bff !important;
}
  /* [data-amplify-authenticator] {
    --amplify-colors-background-primary: var(--amplify-colors-neutral-90);
    --amplify-colors-background-secondary: var(--amplify-colors-neutral-100);
    --amplify-colors-background-tertiary: var(--amplify-colors-black);
    --amplify-components-text-color: var(--amplify-colors-font-interactive);

  } */


  .example-enter {
    opacity: 0.01;
  }
  
  .example-enter.example-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
  }
  
  .example-leave {
    opacity: 1;
  }
  
  .example-leave.example-leave-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
  }

